import Link from 'next/link';
import { useContactsPerRegion } from '../grimme-data-per-region/use-contacts-per-region';
import { LandingPageData, ServicePageData } from '@/lib/butter/butter-types';
import Button from '@grimme/ui-components/button';
import Separator from '@grimme/ui-components/separator';
import Container from '@grimme/ui-components/container';
import { ButterImageV2 } from '../butter-image-v2';

export type ContactSectionProps =
  | LandingPageData['contact_section']
  | ServicePageData['contact_section'];

export function ContactSection(props: ContactSectionProps) {
  const { title, contact_methods } = props;
  const contactsPerRegion = useContactsPerRegion();
  const localRepresentation = contactsPerRegion?.local_representation;

  return (
    <Container className="text-center">
      <h1 className="g-typography-h2">{title}</h1>
      <Separator className="!w-10 !h-1 mb-1 mt-6 mx-auto" />
      <div className="flex flex-wrap justify-center w-full mt-20 gap-20">
        {contact_methods?.map((method) => {
          const linkLocation: string =
            method.region_based_contact && localRepresentation
              ? localRepresentation[method.region_based_contact]
              : method.link_location;
          const linkProps = {
            link: linkLocation,
            text: method.link_text || linkLocation,
            target: '',
          };
          switch (method.contact_method) {
            case 'e-mail':
              linkProps.link = `mailto:${linkLocation}`;
              linkProps.target = '_blank';
              if (method.region_based_contact && localRepresentation) {
                linkProps.text = localRepresentation.email;
              }
              break;
            case 'telephone':
              linkProps.link = `tel:${linkLocation}`;
              linkProps.target = '_blank';
              break;
            case 'whatsapp':
              linkProps.link = `https://wa.me/${linkLocation}`;
              linkProps.target = '_blank';
              break;
          }
          return (
            <div
              key={method.contact_method}
              className="flex flex-col items-center justify-center w-fit mt-19"
            >
              <div className="h-20 relative w-full md:h-28">
                <ButterImageV2
                  fill
                  className="object-contain object-center"
                  src={method.image}
                  alt={method.image_alt}
                />
              </div>

              <p className="text-center w-full pb-2 pt-4 g-typography-h5">
                {method.cta_text || method.contact_method}
              </p>
              <Button
                className="w-full text-center mt-6"
                variant="text"
                asChild
              >
                <Link href={linkProps.link} target={linkProps.target}>
                  {linkProps.text}
                </Link>
              </Button>
            </div>
          );
        })}
      </div>
    </Container>
  );
}
