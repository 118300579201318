import { TextField } from '@grimme/components';
import Button from '@grimme/ui-components/button';
import { generateUtilityClasses, styled } from '@mui/material';

export const classes = generateUtilityClasses('LocationSearchForm', [
  'countrySelect',
]);

export const StyledForm = styled('form')(({ theme }) => ({
  flexWrap: 'wrap',
  gap: theme.spacing(1),
  padding: theme.spacing(3, 0),
  [theme.breakpoints.up('sm')]: {
    flexWrap: 'nowrap',
  },
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(4, 0),
  },
}));

export const StyledTextField = styled(TextField)(() => ({
  flex: 1,
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  flex: '1 100%',
  whiteSpace: 'nowrap',
  [theme.breakpoints.up('sm')]: {
    flex: '0 auto',
  },
}));
